import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_51OyTOEKaFrbWdXakXgNskBU4TmXmqTIP6thT1vf8LjiR1FADapz2jX6Fi5a1h8KpG7hCAiEK9EnlrKbH7wDVGjOs00ReGilVdx'); // Replace with your Stripe publishable key

const ExpressCheckout = () => {
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  useEffect(() => {
  }, []);

  useEffect(() => {
    const setupPaymentButton = async () => {
      const stripe = await stripePromise;

      const appearance = {
        theme: 'stripe',
        variables: {
          borderRadius: '36px',
        }
      };
      const expressCheckoutOptions = {
        buttonHeight: 50,
        buttonTheme: {
          applePay: 'white-outline'
        },
        buttonType: {
          applePay: 'buy',
          googlePay: 'buy',
        },
        paymentMethods: {
          applePay: 'auto',
          googlePay: 'auto'
        }
      };
      const elements = stripe.elements({
        mode: 'payment',
        amount: 1099,
        currency: 'usd',
        appearance,
      });
      const expressCheckoutElement = elements.create('expressCheckout', expressCheckoutOptions);
      expressCheckoutElement.mount('#express-checkout-element');
    };

    setupPaymentButton(); // Call the async function
  }, []);

  return (
    <div>
      <div id="express-checkout-element">
        {!canMakePayment && <p>Payment options not available.</p>}
      </div>
    </div>
  );
};

export default ExpressCheckout;
