import React, { useContext, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { axios, dataFetcher, endpoints, baseUrl } from 'Api';
import { loadStripe } from '@stripe/stripe-js';
import { AppContext, AuthContext } from 'Context';
import styles from './components/page-layout/index.module.css';

const Checkout = ({
  amount,
  promoCodeNew
}) => {
  const { userDetails, setAppSnackbar, getSubscription, autoSignIn } = useContext(AppContext);
  const { getToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const stripePromise = loadStripe('pk_live_51OyTOEKaFrbWdXakXgNskBU4TmXmqTIP6thT1vf8LjiR1FADapz2jX6Fi5a1h8KpG7hCAiEK9EnlrKbH7wDVGjOs00ReGilVdx');
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const handleCheckout = async () => {
    localStorage.setItem('promocode', promoCodeNew);
    const body = new FormData();
    body.append('amount', amount);
    setLoading(true);
    const stripe = await stripePromise;
    const response = await fetch(`${baseUrl}/checkout/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount })
    });
    const { id } = await response.json();
    if (id) {
      console.log(id);
      const { error } = await stripe.redirectToCheckout({ sessionId: id });
      if (error) console.error(error);
    }
    setLoading(false);
  };
  return (
    <div>
      <button
                  className={styles.continueButton}
                  disabled={loading}
                  onClick={handleCheckout}
      >
                  {loading === true
                    ? (
                      <svg className="loading-circle_mini" width="20" height="20" viewBox="0 0 50 50">
                        <circle className="circle" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
                      </svg>
                    ) : 'Checkout'}
      </button>
    </div>
  );
};

export default Checkout;
